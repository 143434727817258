.go-back {
  span {
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &.text-in {
      display: inline-block;
    }
  }
}

.add-job-wrapper {
  display: flex;
  justify-content: flex-end;
}

.job-details-event-in {
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .delete-box {
      cursor: pointer;

      svg {
        fill: red;
      }
    }
  }

  .job-details-inner {
    position: relative;

    .loader-in {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(65, 65, 65, 0.15);
      z-index: 111;
    }
  }
}

.job-position-form {
  label {
    color: #AAAAAA;
    font-size: 14px;
    text-align: left;
  }
}

.rdt {
  ::-webkit-input-placeholder {
    font-size: 14px;
    color: #AAAAAA;
  }

  :-ms-input-placeholder {
    font-size: 14px;
    color: #AAAAAA;
  }

  ::placeholder {
    font-size: 14px;
    color: #AAAAAA;
  }
}

.MuiInputLabel-root {
  color: #AAAAAA !important;
}

.MuiDialogTitle-root {
  min-height: 16.43px;
  padding-top: 24px;
  padding-left: 24px;
  border-bottom: none;
  padding-right: 24px;
  padding-bottom: 0;

  .MuiButtonBase-root {
    color: #999;
    float: right;
    border: 0;
    cursor: pointer;
    opacity: .9;
    padding: 0;
    font-size: inherit;
    background: 0 0;
    margin-top: -12px;
    font-weight: 700;
    line-height: 1;
    text-shadow: none;
    -webkit-appearance: none;
    min-width: 1px;

    .MuiButton-label {
      justify-content: flex-end;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  h4 {
    text-align: center;
    margin: 0;
    line-height: 1.42857143;
  }
}

.open_contract{
  padding: 1px;
  text-align: center;
  color: #00acc1;
  border: 1px solid #00acc1;
  border-radius: 3px;
  &:hover{
    cursor: pointer;
    color: white;
    background-color: #00acc1;
  }
}