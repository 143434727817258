.btn-radio-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    margin-bottom: 5px;
  }
}

.users-link {
  a {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.prefered-jobs-wrapper,
.previous-jobs-wrapper,
.foreign-languages-wrapper {
  text-align: left;

  label {
    display: block;
  }
}

.prefered-jobs-wrapper,
.previous-jobs-wrapper{
  margin: 10px 0;
  .preferedJobsSelect,
  .previousJobsSelect{
    .select__control{
      border: none;
      .select__multi-value{
        color: white;
        background-color: #00bcd4;
        border-radius: 15px;
        .select__multi-value__label{
          color: white;
        }
        .select__multi-value__remove:hover{
          background-color: white;
          color: #00bcd4;
          border: 1px solid #00bcd4;
          border-radius: 15px;
        }
      }
    }
    .select__value-container{
      padding: 0;
    }
  }
}

img {
  max-width: 100%;
}

.employee-img-wrapper {
  text-align: left;

  label {
    margin-bottom: 15px;
  }

  .img-in {
    position: relative;
    margin-top: 15px;

    .remove-img {
      align-items: center;
      background-color: #f44336;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 25px;
      justify-content: center;
      position: absolute;
      padding: 5px;
      right: -12px;
      top: -12px;
      width: 25px;

      svg {
        fill: #fff;
        font-size: 17px;
      }
    }
  }

  .input-file-wrapper {
    position: relative;
    display: inline-flex;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 11;
      cursor: pointer;
    }
  }

  .btn-add-img {
    margin: 25px 0 15px;
  }
}

.date-of-birth-wrapper {
  text-align: left;
}

.error-message {
  font-size: 12px;
  color: #f44336;
  text-align: left;
}

.MuiSelect-select.MuiSelect-select {
  text-align: left;
}