.company-logo-in {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  label {
    font-size: 12px;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left;
    margin-bottom: 8px;
  }
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.u-text-underline {
  text-decoration: underline;
}

.u-text-capitalize {
  text-transform: capitalize;
}

.bw-update-employer {
  margin-top: 20px;

  button {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

// For Delete staff type
.delete-wrapper {
  .deleteBtn {
    &:hover {
      cursor: pointer;
      color: red;
    }
  }
}

.btn {
  margin-top: 5px;
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  // border: 1px solid transparent;
  padding: 0.2rem 0.2rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
}

.btn-info {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
}