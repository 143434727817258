.testimonial-table {

  tr>th,
  tr>td {
    &:first-child {
      max-width: 100px;
      // text-align: center;
    }

    &:last-child {
      max-width: 200px;
      // text-align: center;
    }
  }

  tr>td {}
}

.rt-table {
  td {
    &:last-child {
      text-align: right;
    }
  }
}

.company-logo-in {
  .error-message {
    flex: 0 0 100%;
    max-width: 100%;
  }
}